<template>
  <page-container title="设备型号列表" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="监测设备型号" prop="terminaltypenum">
              <a-input v-model.trim="queryParams.terminaltypenum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="监测设备型号名称" prop="terminaltypename">
              <a-input v-model.trim="queryParams.terminaltypename" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="设备类型" prop="terminalmodlenum">
              <a-select v-model="queryParams.terminalmodlenum">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in terminaltypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">设备型号列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="terminaltypeid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="operation" slot-scope="value, record, index">
            <a-button type="link" size="small" @click="showModal('detail', value, record, index)">详情</a-button>
            <a-button type="link" size="small" @click="showModal('edit', value, record, index)">修改</a-button>
            <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import pagination from '@/mixins/pagination'
import terminaltypes from '@/json/terminaltypes'
import { getTerminaltypeListByCondition, deleteTerminaltype } from 'A/ai'
import addOrEditModal from './addOrEditModal'
export default {
  name:'devicetypeList',
  mixins: [pagination],
  components: {
    addOrEditModal
  },
  data() {
    return {
      showAdvanced: false,
      terminaltypes,
      breadcrumb: [
        {
          name: '网络管理',
          path: ''
        },
        {
          name: '监控硬终端',
          path: ''
        },
        {
          name: '设备型号列表',
          path: ''
        },
      ],
      queryParams: {
        terminaltypenum: '',
        terminaltypename: '',
        terminalmodlenum: '',
      },
      tableColumns: [
        {
          title: '监测设备型号',
          dataIndex: 'terminaltypenum',
          key: 'terminaltypenum',
          ellipsis: true,
        },
        {
          title: '监测设备型号名称',
          dataIndex: 'terminaltypename',
          key: 'terminaltypename',
          ellipsis: true,
        },
        {
          title: '设备类型',
          dataIndex: 'terminalmodlename',
          key: 'terminalmodlename',
          ellipsis: true,
        },
        {
          title: '描述',
          dataIndex: 'terminaltypedesc',
          key: 'terminaltypedesc',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
    }
  },
  computed: {
    terminaltypesMap() {
      let result = {};
      terminaltypes.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    }
  },
  created() {
    this.getTableData();
  },
  methods: {
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      // if(params.terminalmodlenum==''&&params.terminaltypename==''&&params.terminaltypenum==''){
      //   getTerminaltypeListByCondition(params).then(res => {
      //     this.tableLoading = false;
      //     if(res && res.returncode == '0') {
      //       this.tableData = res.item;
      //       this.counts = res.count;
      //     }
      //   }).catch(err => {
      //     this.tableLoading = false;
      //   })
      // }
      getTerminaltypeListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.terminaltypeid);
      }).catch(()=>{
      });
    },
    delete(terminaltypeid) {
      this.showLoading();
      if(terminaltypeid) {
        let params = {
          terminaltypeid
        };
        deleteTerminaltype(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
  }
}
</script>