<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="660" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:14}">
      <a-form-model-item label="设备编号" prop="terminaltypenum">
        <a-input v-model.trim="formDatas.terminaltypenum" :disabled="showType=='detail'" />
      </a-form-model-item>
      <a-form-model-item label="设备型号名称" prop="terminaltypename">
        <a-input v-model.trim="formDatas.terminaltypename" :disabled="showType=='detail'" />
      </a-form-model-item>
      <a-form-model-item label="设备类型" prop="terminalmodlenum">
        <a-select v-model="formDatas.terminalmodlenum" :disabled="showType=='detail'">
          <a-select-option v-for="(item, index) in terminaltypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="terminaltypedesc">
        <a-input v-model.trim="formDatas.terminaltypedesc" :disabled="showType=='detail'" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import terminaltypes from '@/json/terminaltypes'
import { addTerminaltype, modifyTerminaltype } from 'A/ai'
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  data() {
    return {
      terminaltypes,
      modalVisible: false,
      formDatas: {
        terminaltypeid: '',
        terminaltypenum: '',
        terminaltypename: '',
        terminalmodlenum: '',
        terminaltypedesc: '',
      },
      formRules: {
        terminaltypenum: [{required: true, message: '请输入设备型号编号'}],
        terminaltypename: [{required: true, message: '请输入设备名称'}],
        terminalmodlenum: [{required: true, message: '请选择设备类型'}],
      }
    }
  },
  computed: {
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for(let key in this.formDatas) {
            this.formDatas[key] = this.detailData[key]||'';
          }
        })
      }
    },
    resetDatas() {
      this.formDatas.terminaltypeid = '';
      this.$refs.modalForm.resetFields();
    },
    confirm() {
      // success fail
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.showLoading();
            let params = {
              ...this.formDatas
            };
            if(this.showType == 'add') {
              addTerminaltype(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }else {
              modifyTerminaltype(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    }
  }
}
</script>